import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OrderComment, orderData } from '../../admin-dashboard.config';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import { Subject, takeUntil } from 'rxjs';
import autoTable from 'jspdf-autotable';
import { Router } from '@angular/router';
@Component({
  selector: 'tic-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent {
  @Output() selectedOrder = new EventEmitter<any>();
  @ViewChild('tableRef') tableRef: ElementRef;
  @Input() searchString: string='';
  private destroy$ = new Subject<void>();
  orderInfo: orderData[] = orderData;
  displayedColumns: string[] = ['select','orderId', 'events', 'flight', 'hotels', 'status', 'comments', 'createdAt'];
  showCommentsPopup: boolean = false;
  showEmailPopup: boolean = false;
  showSmsPopup: boolean = false;
  showPushPopup: boolean = false;
  showEventDetail: boolean = false;
  showFlightDetail: boolean = false;
  showHotelDetail: boolean = false;
  orderList = [];
  orderListTemp=[];
  checkboxStates = [];
  currentOrder: any;
  emailList = [];
  phoneList = [];

  constructor(public dashboardFacade: DashboardFacade, private router: Router) { 
    this.setInitialState();
    this.dashboardFacade.getOrders();
   }

   ngOnChanges(): void {
    this.orderListTemp=this.orderList;
    if (this.searchString) {
      const searchStringLower = this.searchString.trim().toLowerCase();
    
      this.orderList = this.orderList.filter(item => {
        return this.objectContainsSearchString(item, searchStringLower);
      });
    } else {
      this.dashboardFacade.orders$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data && data.length > 0) {
          this.orderList = data;
        }
      });
    }
  }

  private objectContainsSearchString(obj: any, searchString: string): boolean {
    // Iterate over each property of the object
    return Object.values(obj).some(value => {
      if (typeof value === 'object' && value !== null) {
        // Recursively check nested objects
        return this.objectContainsSearchString(value, searchString);
      } else {
        // Convert non-string values to strings and perform a case-insensitive check
        const stringValue = (value === null || value === undefined) ? '' : value.toString().toLowerCase();
        return stringValue.includes(searchString);
      }
    });
  }

   setInitialState() {
    this.dashboardFacade.orders$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if( data && data.length > 0 ) {
        this.orderList = data;
        this.orderList.forEach((item,index) => {
          this.checkboxStates[index] = {
            state: false,
            data: item
          };
        });
      }
    });
   }

  orderDetail(id: string) {
    this.dashboardFacade.resetOrderDetail();
    this.router.navigate(['/dashboard'],
         {queryParams: {tab: 'singleOrder',orderId: id}});
  }

  openComments(order:any) {
    if(order){
      this.currentOrder=order;
    }
    this.showCommentsPopup = true;
  }

  closePopup() {
    this.showCommentsPopup = false;
  }

  exportToCsv() {
    setTimeout(() => {
        if (this.tableRef && this.tableRef.nativeElement) {
            const table = this.tableRef.nativeElement;
            const rows = table.querySelectorAll('tr');
            const csv = [];

            // Add the header row
            const headerRow = table.querySelector('tr');
            if (headerRow) {
                const headerArray = [];
                headerRow.querySelectorAll('th:not(:first-child)').forEach((headerCell) => {
                    headerArray.push(headerCell.textContent.trim());
                });
                csv.push(headerArray.join(','));
            }

            // Check if any row is checked
            let isAnyRowChecked = false;
            
            // Add the rows
            rows.forEach((row) => {
                const rowArray = [];
                // Check if the row's checkbox is checked
                const checkbox = row.querySelector('td mat-checkbox input');
                if (checkbox && checkbox.checked) {
                    isAnyRowChecked = true;
                    // Exclude the first cell (checkbox cell) from the row data
                    row.querySelectorAll('td:not(:first-child)').forEach((cell) => {
                        rowArray.push(cell.textContent.trim());
                    });
                    csv.push(rowArray.join(','));
                }
            });

            // If no row is checked, add all rows to the CSV data
            if (!isAnyRowChecked) {
                rows.forEach((row) => {
                    const rowArray = [];
                    // Exclude the first cell (checkbox cell) from the row data
                    row.querySelectorAll('td:not(:first-child)').forEach((cell) => {
                        rowArray.push(cell.textContent.trim());
                    });
                    csv.push(rowArray.join(','));
                });
            }

            if (csv.length > 0) { // Check if there are any data rows (excluding the header)
                const csvContent = csv.join('\n');
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, 'checked_table_data.csv');
            } else {
                console.error('No rows selected.');
            }
        } else {
            console.error('Table reference not initialized or element not found.');
        }
    }, 0);
  } 
  
  exportToPdf() {
    if (this.tableRef && this.tableRef.nativeElement) {
        const originalTable = this.tableRef.nativeElement;

        // Use jsPDF instance
        const pdf = new jsPDF();

        // Create an array to hold table headers
        const headers = [];

        // Extract headers from the first row of the original table
        const headerCells = originalTable.querySelectorAll('tr:first-child th');
        headerCells.forEach(cell => {
            headers.push(cell.textContent.trim());
        });

        // Add hierarchy header
        headers.unshift('Hierarchy');

        // Create an array to hold table data
        const data = [];

        // Check if any row is checked
        let isAnyRowChecked = false;
        const rows = originalTable.querySelectorAll('tr');
        let hierarchyCounter = 1;
        for (let i = 1; i < rows.length; i++) {
            const rowData = [];
            const cells = rows[i].querySelectorAll('td');
            const checkbox = rows[i].querySelector('td mat-checkbox input') as HTMLInputElement;
            if (checkbox && checkbox.checked) { // Check if the row is checked
                isAnyRowChecked = true;
                rowData.push(hierarchyCounter.toString()); // Add hierarchy number
                hierarchyCounter++;
                cells.forEach(cell => {
                    rowData.push(cell.textContent.trim());
                });
                data.push(rowData);
            }
        }

        // If no row is checked, add all rows to the PDF data
        if (!isAnyRowChecked) {
            for (let i = 1; i < rows.length; i++) {
                const rowData = [];
                const cells = rows[i].querySelectorAll('td');
                rowData.push(hierarchyCounter.toString()); // Add hierarchy number
                hierarchyCounter++;
                cells.forEach(cell => {
                    rowData.push(cell.textContent.trim());
                });
                data.push(rowData);
            }
        }

        // Add the table to the PDF document using autoTable plugin
        autoTable(pdf, {
            head: [headers],
            body: data
        });

        // Save the PDF with specified file name
        pdf.save('table_data.pdf');
    } else {
        console.error('Table reference not initialized or element not found.');
    }
  }

  singleToggle(event: any, index: number) {
    if( event.checked ) {
      this.checkboxStates[index].state = true;
    } else {
      this.checkboxStates[index].state = false;
    }
    this.collectEmailList();
    this.collectSmsList();
  }

  masterToggle(event: any) {
    if( event.checked ) {
      this.checkboxStates.forEach((item,index) => {
        this.checkboxStates[index].state = true;
      });
    } else {
      this.checkboxStates.forEach((item,index) => {
        this.checkboxStates[index].state = false;
      });
    }
    this.collectEmailList();
    this.collectSmsList();
  }
  
  collectEmailList() {
    let filteredEmails = [];
    for (let i = 0; i < this.checkboxStates.length; i++) {
        if (this.checkboxStates[i].state) {
            const emails: string[] = this.checkboxStates[i].data.contactDetail.email;
            filteredEmails.push(emails);
        }
    }
    this.emailList = filteredEmails;
  }

  collectSmsList() {
    let filteredPhone: string[] = [];
    for (let i = 0; i < this.checkboxStates.length; i++) {
        if (this.checkboxStates[i].state) {
            const number = this.checkboxStates[i].data.contactDetail.mobileNo;
            filteredPhone = filteredPhone.concat(number);
        }
    }
    this.phoneList = filteredPhone;
  }

  hasCheckedStates(): boolean {
    return this.checkboxStates.some(state => state.state);
  }

  sendSms() {
    if (this.hasCheckedStates()) {
      this.showSmsPopup = true;
    }
  }

  closeSmsPopup() {
    this.showSmsPopup = false;
  }

  sendEmail() {
    if (this.hasCheckedStates()) {
      this.showEmailPopup = true;
    }
  }

  closeEmailPopup() {
    this.showEmailPopup = false;

  }

  sentPushNotification() {
    this.showPushPopup = true;
  }

  closePushPopup() {
    this.showPushPopup = false;
  }

  eventDetail(order: any) {
    this.showEventDetail = true;
    this.currentOrder=order;
    // this.dashboardFacade.getEventDetail(id);
  }

  closeEventDetail() {
    this.showEventDetail = false;
  }

  flightDetail(order: any) {
    this.showFlightDetail = true;
    this.currentOrder=order;
  }

  closeFlightDetail() {
    this.showFlightDetail = false;
  }

  hotelDetail(order: any) {
    this.showHotelDetail = true;
    this.currentOrder=order;
  }

  closeHotelDetail() {
    this.showHotelDetail = false;
  }

  sendEmailNotification(event: any) {
    
    if( this.emailList.length > 0 ) {
      event = {
        ...event,
        emails: this.emailList
      }
      this.dashboardFacade.sendEmail(event);
    }
  }
  
  sendSmsNotification(event: any) {
    if( this.phoneList.length > 0 ) {
      event = {
        ...event,
        to: this.phoneList
      }
      this.dashboardFacade.sendSms(event);
    }
  }

  sendOrderComment(event:any){
    let Comment:OrderComment={
      orderId:this.currentOrder.orderId,
      comment:event.comment
    }
    this.dashboardFacade.updateOrderComment(Comment);
    this.closePopup();
    this.dashboardFacade.getOrders();
    this.dashboardFacade.orders$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data && data.length > 0) {
        this.orderList = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}