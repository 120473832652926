import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '../../admin-dashboard.config';

@Component({
  selector: 'tic-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent {
  @Output() selectedItem = new EventEmitter<any>();
  @Output() toggleMenu = new EventEmitter<any>();

  @Input() toggle: boolean;
  isActive: boolean = false;
  menuItems: MenuItem[] = [
    { iconClass: 'fa fa-th', title: 'dashboard.sideNav.home', routerLink: '/#/home-page', code: 'home' },
    { iconClass: 'fa fa-plane', title: 'dashboard.sideNav.allTrip', routerLink: '/#/dashboard?tab=all-trip', code: 'all-trip' },
    { iconClass: 'fa fa-first-order', title: 'dashboard.sideNav.orderDetail', routerLink: '/#/dashboard?tab=orderDetail', code: 'orderDetails' },
    { iconClass: 'fa fa-plane', title: 'dashboard.sideNav.flights', routerLink: '/#/dashboard?tab=flight       ', code: 'flight' },
    { iconClass: 'fa fa-hotel', title: 'dashboard.sideNav.hotels', routerLink: '/#/dashboard?tab=hotels', code: 'hotels' },
    { iconClass: 'fa fa-ticket', title: 'dashboard.sideNav.events', routerLink: '/#/dashboard?tab=events', code: 'events' },
    { iconClass: 'fa fa-map', title: 'dashboard.sideNav.reservation', routerLink: '/#/dashboard?tab=reservation', code: 'reservation' },
    { iconClass: 'fa fa-money', title: 'dashboard.sideNav.coupon', routerLink: '/#/dashboard?tab=coupon-detail', code: 'coupon-detail' },
  ];
  activeMenuCode: string | null = null;

  ngOnChanges() {
    this.isActive = this.toggle;
  }

  closePanel() {
    this.isActive = false;
    this.toggleMenu.emit(false);
  }

  openPanel() {
    this.isActive = true;
    this.toggleMenu.emit(true);
  }

  selectedMenu(event: any) {
    this.activeMenuCode = event;
    this.selectedItem.emit(event);
  }
}
