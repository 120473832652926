import { ChangeDetectorRef, Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DashboardFacade } from "../../../data-access/services/dashboard.facade";
import { Subject, takeUntil } from "rxjs";
import { Coupon } from "../../admin-dashboard.config";
import { format } from "date-fns";
import { AppSupportedCurrencies } from "src/app/libs/data-access/models/consts/app-supported-currencies.const";

@Component({
  selector: "tic-coupon-detail",
  templateUrl: "./coupon-detail.component.html",
  styleUrls: ["./coupon-detail.component.scss"],
})
export class CouponDetailComponent {
  colors = ["#4B49AC", "#FFC100", "#248AFD", "#FF73A6"];
  currenciesData = AppSupportedCurrencies;
  isPopupOpen: boolean = false;
  couponForm: FormGroup;
  private destroy$ = new Subject<void>();
  cnameControl = new FormControl("", [Validators.required]);
  cCodeControl = new FormControl("", [Validators.required]);
  cDiscountTypeControl = new FormControl("", [Validators.required]);
  cValueControl = new FormControl("", [Validators.required]);
  cActiveControl = new FormControl("", [Validators.required]);
  cUsageControl = new FormControl(1, [Validators.required,Validators.min(1)]);
  cCurrencyControl = new FormControl("", []);
  startDateControl = new FormControl("", [Validators.required]);
  endDateControl = new FormControl("", [Validators.required]);
  couponList: any[];
  isEdit: boolean = false;
  CouponInfo: any;
  couponFormTitle:any;
  couponBtnText: any;

  constructor(
    public dashboardFacade: DashboardFacade,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.dashboardFacade.getCoupons();
    this.shuffleColors();
    this.initializeForm();
  }

  shuffleColors(): void {
    for (let i = this.colors.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.colors[i], this.colors[j]] = [this.colors[j], this.colors[i]];
    }
    this.dashboardFacade.coupons$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data != null && data.coupons && data.coupons.length > 0) {
          this.couponList = data.coupons;
        }
      });
  }

  initializeForm() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 5);
    startDate.setDate(startDate.getDate() + 1); 
    this.couponForm = new FormGroup({
      cnameControl: this.cnameControl,
      cCodeControl: this.cCodeControl,
      cDiscountTypeControl: this.cDiscountTypeControl,
      cValueControl: this.cValueControl,
      cActiveControl: this.cActiveControl,
      cUsageControl: this.cUsageControl,
      cCurrencyControl: this.cCurrencyControl,
      startDateControl: this.startDateControl,
      endDateControl: this.endDateControl
    });
    
  }
  checkError(type: any, message: string) {
    let value = type.hasError("required") ? message : "";
    return value;
  }

  onSubmitForm() {
    if (this.couponForm.invalid) {
      for (const control of Object.keys(this.couponForm.controls)) {
        this.couponForm.controls[control].markAsTouched();
      }
      return;
    }
    let coupon: Coupon = {
      name: this.cnameControl.value,
      code: this.cCodeControl.value,
      discountType: this.cDiscountTypeControl.value,
      value: this.cValueControl.value,
      isActive: this.cActiveControl.value === "1" ? true : false,
      fromDate: this.startDateControl.value,
      toDate: this.endDateControl.value,
      usage: this.cUsageControl.value,
      currency: this.cCurrencyControl.value

    };
    if (this.isEdit) {
      this.dashboardFacade.updateCoupon(coupon);
    } else {
      this.dashboardFacade.createCoupon(coupon);
    }
    this.dashboardFacade.CouponInfo$.subscribe((data) => {
      if(Object.keys(data).length){
        if (data.error && data.error === true) {
          this.CouponInfo = data;
        } else {
          if (!data.error && data.error === false){
            this.CouponInfo = data;
            this.dashboardFacade.getCoupons();
            this.dashboardFacade.coupons$
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                if (data != null && data.coupons && data.coupons.length > 0) {
                  this.couponList = data.coupons;
                  this.cdr.markForCheck();
                }
              });
            this.couponForm.reset();
            this.closePopup();
          }          
        }
      }     
    });
    setTimeout(() => this.CouponInfo=null, 3000);
  }

  openPopup(isEdit: any, data: any = {}): void {
    if (isEdit) {
      this.isEdit = true;
      this.setFormValue(data);
      this.isPopupOpen = true;
    } else {
      this.isEdit = false;
      this.isPopupOpen = true;
    }
    this.couponFormTitle=this.isEdit?'dashboard.couponsPage.couponForm.editTitle':'dashboard.couponsPage.couponForm.addTitle';
    this.couponBtnText=this.isEdit?'dashboard.buttons.couponUpdate':'dashboard.buttons.couponSave'; 
  }

  setFormValue(data: any) {
    if (data) {
      this.cnameControl.setValue(data.name);
      this.cCodeControl.setValue(data.code);
      this.cDiscountTypeControl.setValue(
        data.discountType === "percentage" ? "percentage" : "fixed"
      );
      if(data.discountType === 'fixed'){
        this.cDiscountTypeControl.addValidators(Validators.required);
      }else{
        this.cDiscountTypeControl.removeValidators(Validators.required);
      } 
      this.cValueControl.setValue(data.value);
      this.cActiveControl.setValue(data.isActive ? "1" : "0");
      this.startDateControl.setValue(data.fromDate);
      this.endDateControl.setValue(data.toDate);
      this.cUsageControl.setValue(data.usage);
      this.cCurrencyControl.setValue(data.currency);

    } else {
      this.cnameControl.setValue("");
      this.cCodeControl.setValue("");
      this.cDiscountTypeControl.setValue("");
      this.cValueControl.setValue("");
      this.cActiveControl.setValue("");
      this.startDateControl.setValue('');
      this.endDateControl.setValue('');
      this.cUsageControl.setValue(0);
      this.cCurrencyControl.setValue('');
    }
  }

  ClickedOutevt(event: Event): void {
    const evt = event.target as HTMLButtonElement;
    if (evt.classList.contains("coupon-popup")) {
      this.isPopupOpen = false;
    }
  }

  closePopup(): void {
    this.couponForm.reset();
    this.isPopupOpen = false;
  }

  DeleteCoupon(code: any) {
    if (code) {
      this.dashboardFacade.deleteCoupon(code);
      this.dashboardFacade.CouponInfo$.subscribe((data) => {
        data=data.couponInfo?data.couponInfo:{};
        if(Object.keys(data).length){
          if (data.error && data.error === true) {
            this.CouponInfo = data;
          } else {
            if (!data.error && data.error === false){
              this.CouponInfo = data;
              this.dashboardFacade.getCoupons();
              this.dashboardFacade.coupons$
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                  this.couponList = data.coupons;
                  this.cdr.markForCheck();
                });
            }          
          }
          this.dashboardFacade.getCoupons();
        }     
      });
    }    
    setTimeout(() => this.CouponInfo=null, 3000);
  }
}
