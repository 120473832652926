import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DashboardFacade } from '../../../data-access/services/dashboard.facade';
import * as Highcharts from 'highcharts';
import { Subject, takeUntil } from 'rxjs';
import { ViewFilter } from '../../../data-access/model/filters';
@Component({
  selector: 'tic-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent {
  requestQueryEvent: any;
  requestQueryEventIncome: any;
  requestQueryEventOutcome: any;
  requestQueryEventProfit:any;
  eventFilter: any;
  incomeFilter: any;
  outcomeFilter: any;
  profitFilter: any;
  queryChart: string = 'column';
  incomeChart: string = 'column';
  outcomeChart: string = 'column';
  profitChart: string = 'bar';
  statusTitle: string = 'Status';
  status = [
    { value: 'none', viewValue: 'None' },
    { value: 'PENDING', viewValue: 'PENDING' },
    { value: 'READY', viewValue: 'READY' },
    { value: 'IN_PROGRESS', viewValue: 'IN PROGRESS' },
    { value: 'COMPLETED ', viewValue: 'COMPLETED ' },
    { value: 'CANCELLED', viewValue: 'CANCELLED' },
  ];
  priceTitle: string = 'Price';
  price = [
    { value: 'originPrice', viewValue: 'Origin Price' },
    { value: 'ourPrice', viewValue: 'Our Price' },
    { value: 'profit', viewValue: 'Profit' },
  ];
  colors = ['#4B49AC', '#98BDFF'];
  colors1 = ['#4B49AC', '#FFC100'];
  colors2 = ['#248AFD'];
  colors3 = ['#FF73A6'];  
  Highcharts: typeof Highcharts = Highcharts;
  private destroy$ = new Subject();
  public chartOptions: Highcharts.Options;
  public chartOptions1: Highcharts.Options;
  public chartOptions2: Highcharts.Options;
  public chartOptions3: Highcharts.Options;
  showSaveViewPopup: boolean = false;
  showValidation: boolean;
  Info: any;
  eventView: any;
  
  constructor(private cdr: ChangeDetectorRef,public dashboardFacade: DashboardFacade) {
  }

  ngOnInit() {
    this.setInitialState();
  }

  setInitialState() {
    this.dashboardFacade.loadPopularEvents({});
    this.dashboardFacade.loadEventsIncome({});
    this.dashboardFacade.loadEventsOutcome({});
    this.dashboardFacade.loadEventsProfit({});

    this.toggleChart(this.queryChart, "events");
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
    this.toggleChart(this.profitChart, "profit");

    let user = localStorage.getItem('userId');
    this.dashboardFacade.getEventsView({username: user});
    this.dashboardFacade.EventsView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.eventView = view;
    });
  }

  filterByRange(event: any,type: string) {
    if( type === 'events') {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryEvent = {
          ...this.requestQueryEvent,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryEvent;
        this.requestQueryEvent = rest;
      }
      this.dashboardFacade.loadPopularEvents(this.requestQueryEvent);
    } 
    else if( type === 'income' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryEventIncome = {
          ...this.requestQueryEventIncome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryEventIncome;
        this.requestQueryEventIncome = rest;
      }
      this.dashboardFacade.loadEventsIncome(this.requestQueryEventIncome);
    } 
    else if( type === 'outcome' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryEventOutcome = {
          ...this.requestQueryEventOutcome,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryEventOutcome;
        this.requestQueryEventOutcome = rest;
      }
      this.dashboardFacade.loadEventsOutcome(this.requestQueryEventOutcome);
    }
    else if( type === 'profit' ) {
      if( event != 'none') {
        const obj = {
          fromDate: event.startDate,
          toDate: event.endDate 
        }
        this.requestQueryEventProfit = {
          ...this.requestQueryEventProfit,
          ...obj
        }
      } else {
        const { fromDate, toDate, ...rest } = this.requestQueryEventProfit;
        this.requestQueryEventProfit = rest;
      }
      this.dashboardFacade.loadEventsProfit(this.requestQueryEventProfit);
    }
  }

  dataFilter(event: any,type: string,dropdownType: string) {
    if( type === 'events') {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.eventFilter = {
            ...this.eventFilter,
            status: event
          }
        } else {
          const { status, ...rest } = this.eventFilter;
          this.eventFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.eventFilter = {
              ...this.eventFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.eventFilter = {
              ...this.eventFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.eventFilter;
          this.eventFilter = rest;
        }
      }
      this.requestQueryEvent = {
        ...this.requestQueryEvent,
        filters: this.eventFilter
      }
      this.dashboardFacade.loadPopularEvents(this.requestQueryEvent);
    } else if( type === 'income' ) {
        if( dropdownType === 'status' ) {
          if( event != 'none' ) {
            this.incomeFilter = {
              ...this.incomeFilter,
              status: event
            }
          } else {
            const { status, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        } else if( dropdownType === 'price' ) {
          if( event.option != 'none' ) {
            if( event.option ) {
              this.incomeFilter = {
                ...this.incomeFilter,
                priceType: event.option,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            } else {
              this.incomeFilter = {
                ...this.incomeFilter,
                minPrice: event.minPrice,
                maxPrice: event.maxPrice
              }
            }
          } else {
            const { priceType, minPrice, maxPrice, ...rest } = this.incomeFilter;
            this.incomeFilter = rest;
          }
        }
        this.requestQueryEventIncome = {
          ...this.requestQueryEventIncome,
          filters: this.incomeFilter
        }
        this.dashboardFacade.loadEventsIncome(this.requestQueryEventIncome);
    } else if( type === 'outcome' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.outcomeFilter = {
            ...this.outcomeFilter,
            status: event
          }
        } else {
          const { status, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.outcomeFilter = {
              ...this.outcomeFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.outcomeFilter;
          this.outcomeFilter = rest;
        }
      }
      this.requestQueryEventOutcome = {
        ...this.requestQueryEventOutcome,
        filters: this.outcomeFilter
      }
      this.dashboardFacade.loadEventsOutcome(this.requestQueryEventOutcome);
    } else if( type === 'profit' ) {
      if( dropdownType === 'status' ) {
        if( event != 'none' ) {
          this.profitFilter = {
            ...this.profitFilter,
            status: event
          }
        } else {
          const { status, ...rest } = this.profitFilter;
          this.profitFilter = rest;
        }
      } else if( dropdownType === 'price' ) {
        if( event.option != 'none' ) {
          if( event.option ) {
            this.profitFilter = {
              ...this.profitFilter,
              priceType: event.option,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          } else {
            this.profitFilter = {
              ...this.profitFilter,
              minPrice: event.minPrice,
              maxPrice: event.maxPrice
            }
          }
        } else {
          const { priceType, minPrice, maxPrice, ...rest } = this.profitFilter;
          this.profitFilter = rest;
        }
      }
      this.requestQueryEventProfit = {
        ...this.requestQueryEventProfit,
        filters: this.profitFilter
      }
      this.dashboardFacade.loadEventsProfit(this.requestQueryEventProfit);
    } 
  } 

  toggleChart(event: any, type: string) {
    if (type === "events") {
      this.dashboardFacade.popularEventsInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.queryChart = event;
            const events = Object.keys(data);
            const seriesData = events.map((event) => {
              const orderIds = data[event].map((item) => item.orderId);
              return {
                name: event || 'Unknown', // Use a meaningful name for each category
                y: orderIds.length,
                orderIds: orderIds, // Store all order IDs for tooltip
              };
            });

            this.chartOptions = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  showInLegend: false,
                  colorByPoint: true,
                  colors: this.colors,
                  borderRadius: 6
                },
              ],
              colors: this.colors,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: events.map((airline) => airline || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Events",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Orders Count",
                },
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "income") {
      this.dashboardFacade.eventsIncomeInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.incomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const income = data[category].income;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: income,
                orderIds: income, // Store all order IDs for tooltip
              };
            });

            this.chartOptions1 = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colorByPoint: true,
                  colors: this.colors1,
                  showInLegend: false,
                },
              ],
              colors: this.colors1,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Income",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "outcome") {
      this.dashboardFacade.eventsOutcomeInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.outcomeChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const outcome = data[category].outcome;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: outcome,
                orderIds: outcome, // Store all order IDs for tooltip
              };
            });

            this.chartOptions2 = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  colors: this.colors2,
                  showInLegend: false,
                },
              ],
              colors: this.colors2,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Outcome",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    } else if (type === "profit") {
      this.dashboardFacade.eventsProfitInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data) {
            this.profitChart = event;
            const categories = Object.keys(data);
            const seriesData = categories.map((category) => {
              const profit = data[category][0].profit;
              return {
                name: category || 'Unknown', // Use a meaningful name for each category
                y: profit,
                orderIds: profit, // Store all order IDs for tooltip
              };
            });

            this.chartOptions3 = {
              series: [
                {
                  type: event, // Use 'column' type for a column chart
                  data: seriesData,
                  showInLegend: false,
                },
              ],
              colors: this.colors3,
              title: null,
              xAxis: {
                visible: true,
                type: "category",
                categories: categories.map((cat) => cat || 'Unknown'), // Display as 'Package 2', 'Package 3', etc.
                title: {
                  text: "Date",
                },
              },
              yAxis: {
                visible: true,
                title: {
                  text: "Profit",
                },labels: {
                  formatter: function () {
                      return '$' + this.value; // Format yAxis labels with $ sign
                  }
              }
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
              },
              credits: {
                enabled: false,
              },
            };
          }
        });
    }
  }

  saveView(event:any) {
    let name =event.viewName;   
    let username=localStorage.getItem('userId');
    const eventFilters = {
      requestQueryEvent: this.requestQueryEvent,
      queryChart: this.queryChart,
      requestQueryEventIncome: this.requestQueryEventIncome,
      incomeChart: this.incomeChart,
      requestQueryEventOutcome: this.requestQueryEventOutcome,
      outcomeChart: this.outcomeChart,
      requestQueryEventProfit: this.requestQueryEventProfit,
      profitChart: this.profitChart
    }
    let data: ViewFilter={
      username:username,
      view:'event',
      singleView: {
        name: name,
        param: this.objectToBase64(eventFilters)
       }
    }
    this.dashboardFacade.saveView(data);
    this.dashboardFacade.SaveViewInfo$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this.Info = info;
    });
    this.showValidation = true;
    setTimeout(() => {
      this.showValidation = false;
      this.dashboardFacade.getEventsView({username: username});
    }, 3000);
    this.showSaveViewPopup = false; 
  }
  
  objectToBase64(obj: any): string {
    const jsonStr = JSON.stringify(obj);
    return btoa(jsonStr);
  }

  base64ToObject(base64Str: string): any {
    const jsonStr = atob(base64Str);
    return JSON.parse(jsonStr);
  }

  onSelectionChange(event: any) {
    var eventFilter;
    if( event.value ) {
      eventFilter = this.base64ToObject(event.value);
    } else {
      eventFilter = {};
    }
    if( eventFilter && eventFilter.requestQueryEvent ) {
      this.requestQueryEvent = eventFilter.requestQueryEvent;
      this.dashboardFacade.loadPopularEvents(this.requestQueryEvent);
      if( eventFilter.requestQueryEvent.filters ) {
        this.queryChart = eventFilter.requestQueryEvent.filters;
      }
    } else {
      this.dashboardFacade.loadPopularEvents({});
    }
    if( eventFilter && eventFilter.requestQueryEventIncome ) {
      this.requestQueryEventIncome = eventFilter.requestQueryEventIncome;
      this.dashboardFacade.loadEventsIncome(this.requestQueryEventIncome);
      if( eventFilter.requestQueryEventIncome.filters ) {
        this.incomeFilter = eventFilter.requestQueryEventIncome.filters;
      }
    } else {
      this.dashboardFacade.loadEventsIncome({});
    }
    if( eventFilter && eventFilter.requestQueryEventOutcome ) {
      this.requestQueryEventOutcome = eventFilter.requestQueryEventOutcome;
      this.dashboardFacade.loadEventsOutcome(this.requestQueryEventOutcome);
      if( eventFilter.requestQueryEventOutcome.filters ) {
        this.outcomeFilter = eventFilter.requestQueryEventOutcome.filters;
      }
    } else {
      this.dashboardFacade.loadEventsOutcome({});
    }
    if( eventFilter && eventFilter.requestQueryEventProfit ) {
      this.requestQueryEventProfit = eventFilter.requestQueryEventProfit;
      this.dashboardFacade.loadEventsProfit(this.requestQueryEventProfit);
      if( eventFilter.requestQueryEventProfit.filters ) {
        this.profitFilter = eventFilter.requestQueryEventProfit.filters;
      }
    } else {
      this.dashboardFacade.loadEventsProfit({});
    }
    if( eventFilter && eventFilter.queryChart ) {
      this.queryChart = eventFilter.queryChart;
    } else {
        this.queryChart = 'column';
    }
    if( eventFilter && eventFilter.incomeChart ) {
      this.incomeChart = eventFilter.incomeChart;
    } else {
        this.incomeChart = 'column';
    }
    if( eventFilter && eventFilter.outcomeChart ) {
      this.outcomeChart = eventFilter.outcomeChart;
    } else {
        this.outcomeChart = 'column';
    }
    if( eventFilter && eventFilter.profitChart ) {
      this.profitChart = eventFilter.profitChart;
    } else {
        this.profitChart = 'column';
    }
    this.toggleChart(this.queryChart, "events");
    this.toggleChart(this.incomeChart, "income");
    this.toggleChart(this.outcomeChart, "outcome");
    this.toggleChart(this.profitChart, "profit");
  }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }
  openSaveView(){
    this.showSaveViewPopup = true;
  }
  closeViewPopup() {
    this.showSaveViewPopup = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
