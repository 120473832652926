<div class="login-section desktop">
    <div class="image-col">
        <img src="../../../../../assets/images/login.png">
    </div>
    <div class="login-form">
        <div class="form-col">
            <h2>{{'dashboard.login.heading' | i18n}}</h2>
            <p>{{'dashboard.login.subHeading' | i18n}}</p>
            <tic-login-form></tic-login-form>
        </div>
    </div>
</div>
<div class="login-section tablet">
    <h1 class="title">Book your next journey</h1>
    <p class="description">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        when an unknown printer took a galley of type </p>
    <div class="form-col">
        <h2>{{'dashboard.login.heading' | i18n}}</h2>
        <p>{{'dashboard.login.subHeading' | i18n}}</p>
        <tic-login-form></tic-login-form>
    </div>
</div>