<div class="dashboard-head col-lg-12">
    <div class="inner-options">
        <a class="navbar-brand" (click)="navigateToHome()"
          ><img src="assets/images/logo.png" alt="Trripi"
        /></a>
        <div class="hambuger-search">
            <div class="hambugger-menu" (click)="toggleSidebar()">
                <img width="37" height="37" src="assets/images/hambuger.png" >
            </div>
            <div class="search-option" *ngIf="showSearching">
                <div class="input-group rounded">
                    <span class="input-group-text border-0" id="search-addon">
                        <img width="30" height="30" src="assets/images/search.png" >
                    </span>
                    <input type="search" class="form-control rounded" placeholder="{{'dashboard.buttons.searchLabel' | i18n}}" aria-label="Search" aria-describedby="search-addon" (keyup)="searchInOrderList($event)"/>
                </div>
            </div>
        </div>
        <div class="user-profile">
            <tic-language-panel matTooltip='Switch Language'></tic-language-panel>
            <button (click)="logout()">{{'dashboard.buttons.logout' | i18n}}</button>
            <img width="58" height="58" src="assets/images/user.png" >
        </div>
    </div>
</div>