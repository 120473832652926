import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  CheckoutViewModel,
  ICheckoutFacade,
  makeCheckoutViewModel,
} from "../../../models";
import { CheckoutQuery } from "./checkout.query";


@Injectable({
  providedIn: "root",
})
export class CheckoutFacade implements ICheckoutFacade {
  events$: Observable<any[]> = this.CheckoutQuery.events$;
  flight$: Observable<any[]> = this.CheckoutQuery.flight$;
  hotel$: Observable<any[]> = this.CheckoutQuery.hotel$;
  room$: Observable<any> = this.CheckoutQuery.room$;
  passangerDetail$: Observable<any> = this.CheckoutQuery.passangerDetail$;
  orderInfo$: Observable<number> = this.CheckoutQuery.orderInfo$;
  ids$: Observable<any> = this.CheckoutQuery.ids$;
  totalPrice$: Observable<number> = this.CheckoutQuery.totalPrice$;
  packagePrice$: Observable<number> = this.CheckoutQuery.packagePrice$;
  eventPrice$: Observable<number> = this.CheckoutQuery.eventPrice$;
  flightPrice$: Observable<number> = this.CheckoutQuery.flightPrice$;
  hotelPrice$: Observable<number> = this.CheckoutQuery.hotelPrice$;
  loading$: Observable<boolean> = this.CheckoutQuery.loading$;
  loaded$: Observable<boolean> = this.CheckoutQuery.loaded$;
  errors$: Observable<any> = this.CheckoutQuery.errors$;
  readonly saveEventOrderInfo$: Observable<any> =
    this.CheckoutQuery.saveEventOrderInfo$;
  readonly eventOrderInfo$: Observable<any> =
    this.CheckoutQuery.eventOrderInfo$;
  readonly saveHotelOrderInfo$: Observable<any> =
    this.CheckoutQuery.savHotelOrderInfo$;
  readonly hotelOrderInfo$: Observable<any> =
    this.CheckoutQuery.hotelOrderInfo$;
  readonly saveFlightOrderInfo$: Observable<any> =
    this.CheckoutQuery.saveFlightOrderInfo$;
  readonly flightOrderInfo$: Observable<any> =
    this.CheckoutQuery.flightOrderInfo$;
  readonly saveOrderInfo$: Observable<any> = this.CheckoutQuery.saveOrderInfo$;
  readonly eventOrdersDetailInfo$: Observable<any> =
    this.CheckoutQuery.eventOrdersDetailInfo$;
    readonly couponDetail$: Observable<any> =
    this.CheckoutQuery.getCouponDetail$;
  vm$!: Observable<CheckoutViewModel>;
  preSearchEvents$: Observable<any[]> = this.CheckoutQuery.preSearchEvents$;
  preSearchFlight$: Observable<any[]> = this.CheckoutQuery.preSearchFlight$;
  preSearchHotel$: Observable<any[]> = this.CheckoutQuery.preSearchHotel$;
  saveMultipleEventOrderInfo$: Observable<any[]> = this.CheckoutQuery.saveMultipleEventOrderInfo$;
  
  
  constructor(private CheckoutQuery: CheckoutQuery,) {
    this.vm$ = makeCheckoutViewModel([
      this.events$,
      this.flight$,
      this.hotel$,
      this.room$,
      this.passangerDetail$,
      this.orderInfo$,
      this.ids$,
      this.totalPrice$,
      this.packagePrice$,
      this.eventPrice$,
      this.flightPrice$,
      this.hotelPrice$,
      this.loading$,
      this.loaded$,
      this.errors$,
      this.saveEventOrderInfo$,
      this.eventOrderInfo$,
      this.saveHotelOrderInfo$,
      this.hotelOrderInfo$,
      this.saveFlightOrderInfo$,
      this.flightOrderInfo$,
      this.saveOrderInfo$,
      this.preSearchEvents$,
      this.preSearchFlight$,
      this.preSearchHotel$,
      this.eventOrdersDetailInfo$,
      this.saveMultipleEventOrderInfo$,
      this.couponDetail$
    ]);
  }

  setEvent(events: any[]): void {
    //TODO: complete type
    this.CheckoutQuery.setEvents(events);
  }

  setFlight(flight: any[]): void {
    //TODO: complete type
    this.CheckoutQuery.setFlight(flight);
  }

  setHotel(hotel: any[]): void {
    //TODO: complete type
    this.CheckoutQuery.setHotel(hotel);
  }

  setRoom(room: any): void {
    this.CheckoutQuery.setRoom(room);
  }

  setTotalPrice(totalPrice: number): void {
    //TODO: complete type
    this.CheckoutQuery.setTotalPrice(totalPrice);
  }

  setPackagePrice(packagePrice: number) {
    this.CheckoutQuery.setPackagePrice(packagePrice);
  }

  setEventPrice(eventPrice: number) {
    this.CheckoutQuery.setEventPrice(eventPrice);
  }

  setFlightPrice(flightPrice: number) {
    this.CheckoutQuery.setFlightPrice(flightPrice);
  }

  setHotelPrice(hotelPrice: number) {
    this.CheckoutQuery.setHotelPrice(hotelPrice);
  }
  setPassangerDetail(passangerDetail: any): void {
    this.CheckoutQuery.setPassangerDetail(passangerDetail);
  }
  resetOrderInfo(): void {
    this.CheckoutQuery.resetOrderInfo();
  }
  resetSaveOrderInfo():void{
    this.CheckoutQuery.resetSaveOrderInfo();
  }
  resetSaveEventOrderInfo():void{
    this.CheckoutQuery.resetSaveEventOrderInfo();
  }
  resetSaveHotelOrderInfo():void{
    this.CheckoutQuery.resetSaveHotelOrderInfo();
  }
  resetSaveFlightOrderInfo():void{
    this.CheckoutQuery.resetSaveFlightOrderInfo();
  }
  saveOrder(orderDetail: any): void {
    this.CheckoutQuery.saveOrder(orderDetail);
  }
  getOrder(orderRequest: any): void {
    this.CheckoutQuery.getOrder(orderRequest);
  }
  saveEventOrder(orderRequest: any): void {
    this.CheckoutQuery.saveEventOrder(orderRequest);
  }
  saveMultipleEventOrder(orderRequest: any): void {
    this.CheckoutQuery.saveMultipleEventOrder(orderRequest);
  }
  getEventOrder(orderRequest: any): void {
    this.CheckoutQuery.getEventOrder(orderRequest);
  }
  saveHotelOrder(orderRequest: any): void {
    this.CheckoutQuery.saveHotelOrder(orderRequest);
  }
  getHotelOrder(orderRequest: any): void {
    this.CheckoutQuery.getHotelOrder(orderRequest);
  }
  saveFlightOrder(orderRequest: any): void {
    this.CheckoutQuery.saveFlightOrder(orderRequest);
  }
  getFlightOrder(orderRequest: any): void {
    this.CheckoutQuery.getFlightOrder(orderRequest);
  }

  getPreSearchedEvent(requestQuery: any): void {
    //TODO: Complete type
    this.CheckoutQuery.getPreSearchedEvent(requestQuery);
  }

  getPreSearchedFlight(requestQuery: any): void {
    //TODO: Complete type
    this.CheckoutQuery.getPreSearchedFlight(requestQuery);
  }

  getPreSearchedHotel(requestQuery: any): void {
    //TODO: Complete type
    this.CheckoutQuery.getPreSearchedHotel(requestQuery);
  }
  getEventOrdersDetail(requestQuery: any): void {
    //TODO: Complete type
    this.CheckoutQuery.getEventOrdersDetail(requestQuery);
  }
  getCouponDetail(requestQuery: any): void {
    //TODO: Complete type
    this.CheckoutQuery.getCouponDetail(requestQuery);
  }
  updateCouponCount(requestQuery: any): void {
    //TODO: Complete type
    this.CheckoutQuery.updateCouponCount(requestQuery);
  }

  removeEvent(eventId: string) {
    this.CheckoutQuery.removeEvent(eventId);
  }
}
