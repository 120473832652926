import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, throwError } from "rxjs";
import { SortValueEnum } from "src/app/libs/ui/components/top-bar/enums/top-bar.enum";
import { FlightsServiceRoutes } from "./flights.routes";
import { FlightPageUtils } from "../../data-access/utils/flights-page.utils";
import { RouterService } from "src/app/libs/data-access/services/router/router.service";
import { environment } from "src/app/core/environments/environment";
@Injectable({
  providedIn: "root",
})

export class FlightsService {


  constructor(
    private HttpClient: HttpClient,
    private routerService: RouterService
     ) {}

  getFilterOptions(filterOptionsRequest: any) {
    return this.HttpClient.post(
      `${environment.ms_flights_base_url}/${FlightsServiceRoutes.Flight_Search}/search/loadAllFilterOptions`,
      filterOptionsRequest
    ).pipe(map((res: any) => res),
      catchError((_error) => {
        this.getFilterFailed();
        return throwError('An error occurred while fetching filter options');
      })
    );
  }

  getFilterFailed() {
    this.routerService.setFilterState(true);
  }

  getFlights(getFlightsRequest: any) {
    //TODO: complete types and get it as props
    return this.HttpClient.post(
      `${environment.ms_flights_base_url}/${FlightsServiceRoutes.Flight_Search}/search/filterAndSortFlights`,
      getFlightsRequest
    ).pipe(map((res: any) => res),
      catchError((_error) => {
        this.getFilterFailed();
        return throwError('An error occurred while fetching filter options');
      })
    );
    /*
      For Later Use
      return this.HttpClient.post(this.apiUrl, getFlightsRequest).pipe(map((res: any) => res));
      return of(MockedFlightService.getFlightsMock.response);
    */
  }

  listFlights(
    offerRequestId: string,
    afterCursor: string,
    sort: SortValueEnum
  ) {
    //TODO: complete types and get it as props
    return this.HttpClient.get(
      `${environment.ms_flights_base_url}/${FlightsServiceRoutes.Flight_Search}/${FlightsServiceRoutes.List_Offers}/${offerRequestId}`,
      { params: { ...FlightPageUtils.SEARCH_PARAMS, after: afterCursor, sort } }
    ).pipe(map((res: any) => res),
      catchError((_error) => {
        this.getFilterFailed();
        return throwError('An error occurred while fetching filter options');
      })
    );

    //return of(MockedFlightService.listFlights.response);
  }
  
}
